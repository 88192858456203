import React from 'react'
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import ProseRichTextWhite from '../components/rich_text_white'
import ProseRichText from '../components/rich_text'
import Seo from '../components/seo'

const HomePage = ({data}) => {
  const entry = data.prismicHomePage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image='' pathname={`/` + entry.uid + `/`} />
      <div className="bg-steel-blue pt-3"></div>
      <div className="w-full pt-12 pb-6 lg:pb-12 bg-architect">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex justify-between items-center gap-12">
            <div className="w-full lg:w-1/4">
              <StaticImage className="w-48 lg:w-72" placeholder="none" src="../images/alternate_logo.jpg" alt="Linear Detailing" />
              <div className="block font-verdana lg:hidden mt-6 lg:mt-0">
                <a href={`tel:${entry.data.phone}`} title={entry.data.phone} rel="nofollow noopener">
                  {entry.data.phone}
                </a>
              </div>
            </div>
            <div className="hidden lg:block lg:w-3/4">
              <div className="flex justify-end items-center gap-6">
                <div className="w-2/4">
                  &nbsp;
                </div>
                <div className="w-1/4">
                  <div className="font-verdana text-right">
                    <div className="flex justify-end items-center gap-3 mb-1">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                      </svg>
                      <h3 className="font-bold">Email</h3>
                    </div>
                    <a href={`mailto:${entry.data.email}`} title="Make enquiry" rel="nofollow noopener">{entry.data.email}</a>
                  </div>  
                </div>
                <div className="w-1/4">
                  <div className="font-verdana text-base text-right">
                    <div className="flex justify-end items-center gap-3 mb-1">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                      </svg>
                      <h3 className="font-bold">Contact</h3>
                    </div>
                    <a href={`tel:${entry.data.phone}`} title={entry.data.phone} rel="nofollow noopener" className="underline">
                      {entry.data.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative">
        <figure className="absolute -inset-0 mt-6">
          <div className="w-full h-full bg-black opacity-25 absolute -inset-0" />
            <GatsbyImage 
              className="w-full h-full block object-cover" 
              image={entry.data.banner_image.gatsbyImageData} 
              alt={entry.data.banner_image.alt ?? ''}
            />
        </figure>
        <div className="hidden lg:block w-full bg-warm-grey relative">
          <div className="font-verdana w-10/12 mx-auto max-w-screen-xl">
            <div className="flex justify-between gap-12 text-white font-verdana">
              <div className="py-3 px-6 bg-grey-e text-black">
                Home
              </div>
              <div className="font-verdana font-bold py-3 px-6 bg-steel-blue text-right">
                <a href={`mailto:${entry.data.email}`} title="Contact" rel="nofollow noopener">
                  <div className="flex items-center gap-3">
                    <div>
                      Make enquiry
                    </div>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <figcaption className="w-full pt-36 lg:pt-60 pb-36 relative">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <ProseRichTextWhite props={entry.data.banner_text.richText} />
            <div className="mt-12 font-verdana font-bold px-6 py-3 bg-steel-blue text-white inline-block">
              <a href={`mailto:${entry.data.email}`} title="Contact" rel="nofollow noopener">
                <div className="flex items-center gap-3">
                  <div>
                    Make enquiry
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </figcaption>
      </div>

      <div className="w-full py-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="mb-12">
            <ProseRichText props={entry.data.services_text.richText} />
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
          {entry.data.services.map((entry, index) => (
            <div key={`services_${index}`}>
              <figure>
                <GatsbyImage 
                  className="w-full h-full block object-cover aspect-w-4 aspect-h-3" 
                  image={entry.image.gatsbyImageData} 
                  alt={entry.image.alt ?? ''}
                />
              </figure>
              <div className="mt-6">
                <ProseRichText props={entry.text.richText} />
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>

      <div className="w-full py-24 bg-grey-e">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none lg:flex justify-between items-center gap-24">
            <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
              <div className="text-deep-charcoal font-verdana font-bold mb-3">
                <h2>About us</h2>
              </div>
              <div className="mb-12">
                <ProseRichText props={entry.data.about_text.richText} />
              </div>
              <div className="font-verdana font-bold px-6 py-3 bg-steel-blue hover:bg-black text-white inline-block">
                <a href={`mailto:${entry.data.email}`} title="Contact" rel="nofollow noopener">
                  <div className="flex items-center gap-3">
                    <div>
                      {entry.data.email}
                    </div>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <GatsbyImage 
                className="w-full h-full block object-cover" 
                image={entry.data.about_image.gatsbyImageData} 
                alt={entry.data.about_image.alt ?? ''}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-10/12 py-24 mx-auto max-w-screen-xl">
          <div className="text-deep-charcoal font-verdana font-bold mb-3">
            <h2>Why us?</h2>
          </div>
          <div className="mb-12">
            <ProseRichText props={entry.data.why_text.richText} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
            {entry.data.why.map((entry, index) => (
              <div key={`why_${index}`}>
                <figure>
                  <GatsbyImage 
                    className="w-full h-full block object-cover aspect-w-4 aspect-h-3" 
                    image={entry.image.gatsbyImageData} 
                    alt={entry.image.alt ?? ''}
                  />
                </figure>
                <div className="mt-6">
                  <ProseRichText props={entry.text.richText} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full py-24 bg-steel-blue bg-architect">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="mb-12">
            <ProseRichTextWhite props={entry.data.review_text.richText} />
          </div>
          <div className="flex-none lg:flex justify-between bg-white">
            <div className="w-full lg:w-1/2 ">
              <img className="w-full h-full object-cover block" src="../img/cheers_team_phil_blake.jpg" alt="" />
            </div>
            <div className="w-full lg:w-1/2 p-6 lg:p-24">
              <div className="prose-p:text-xl lg:prose-p:text-2xl">
                <ProseRichText props={entry.data.review_quote.richText} />
              </div>
              <div className="mt-6 text-deep-charcoal text-sm font-bold font-verdana">
                &mdash; {entry.data.review_author}, <span className="text-xs font-normal">{entry.data.review_company}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-10/12 pt-24 mx-auto max-w-screen-xl">
          <div className="font-verdana font-bold mb-3">
            <h2>Projects</h2>
          </div>
          <div className="flex-none lg:flex justify-between gap-12">
            <div className="w-full lg:w-1/2">
              <div className="mb-12">
                <ProseRichText props={entry.data.projects_text.richText} />
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="mb-12">
                <ProseRichText props={entry.data.projects_text_2.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-10/12 lg:w-full pb-24 mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
            {entry.data.projects.map((entry, index) => (
              <div key={`projects_${index}`}>
                <figure>
                  <GatsbyImage 
                    className="w-full h-full block object-cover aspect-w-4 aspect-h-3" 
                    image={entry.image.gatsbyImageData} 
                    alt={entry.image.alt ?? ''}
                  />
                </figure>
                <div className="mt-6">
                  <ProseRichText props={entry.text.richText} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full relative">
        <figure className="absolute -inset-0">
          <GatsbyImage 
            className="w-full h-full block object-cover" 
            image={entry.data.action_image.gatsbyImageData} 
            alt={entry.data.action_image.alt ?? ''}
          />
        </figure>
        <div className="flex justify-end items-end relative">
          <figcaption className="w-full lg:w-1/3">
            <div className="mt-72 mr-0 lg:mr-12 mb-12">
              <div className="p-6 lg:p-12 bg-steel-blue">
                <h2 className="font-verdana uppercase text-white text-2xl lg:text-4xl font-normal leading-none">Creating solid foundations</h2>
                <p className="font-verdana pt-6 text-lg max-w-none text-white">Proudly 100% New Zealand owned and operated our skilled team are here to help.</p>
                <div className="mt-12 font-verdana font-bold px-6 py-3 bg-white text-steel-blue inline-block">
                  <a href={`mailto:${entry.data.email}`} title="Contact" rel="nofollow noopener">
                    <div className="flex items-center gap-3">
                      <div>
                        {entry.data.email}
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </figcaption>
        </div>
      </div>
      <div className="w-full bg-grey-e py-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none lg:flex justify-between gap-24">
            <div className="w-full lg:w-1/3">
              <h3 className="mb-3 font-verdana uppercase font-bold text-steel-blue">Contact</h3>
              <ul className="flex flex-col gap-3 font-verdana">
                <li>{entry.data.address}</li>
                <li>
                  <div className="flex items-center gap-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                    <a href={`tel:${entry.data.phone}`} title={entry.data.phone} rel="nofollow noopener">
                      {entry.data.phone}
                    </a>
                  </div>
                </li>
                <li>
                  <a href={`mailto:${entry.data.email}`} title="Contact" rel="nofollow noopener">
                    {entry.data.email}
                  </a>
                </li>
                <li className="mt-12">
                  If you'd like to join our team <a href={`mailto:${entry.data.jobs}`} title="Contact" rel="nofollow noopener">
                    {entry.data.jobs}
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-2/3 mt-12 lg:mt-0">
              <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3000.834457627226!2d174.87677657590987!3d-41.22537787132087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d38ab1cb57a7509%3A0x2d6eb9e20a84d708!2sLinear%20Detailing!5e0!3m2!1sen!2snz!4v1689040002960!5m2!1sen!2snz" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-6 font-verdana text-deep-charcoal">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none lg:flex justify-between items-center gap-12 mt-12 lg:mt-0">
            {entry.data.logos.map((entry, index) => (
              <figure key={`logos_${index}`} className="w-1/2 lg:w-full mb-12 lg:mb-0">
                <GatsbyImage 
                  className="w-full h-full block object-contain" 
                  image={entry.image.gatsbyImageData} 
                  alt={entry.image.alt ?? ''}
                />
              </figure>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full py-6 font-verdana text-deep-charcoal">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex justify-between items-center">
            <div className="w-1/2">
              &copy;2023 All rights reserved.
            </div>
            <div className="w-1/2">
              <div className="flex gap-3 justify-end">
                <div className="p-1 bg-black">
                  <img src="../img/linkedin.svg" alt="" className="w-6 h-6 bg-white" />
                </div>
                <div className="p-1 bg-black">
                  <img src="../img/facebook.svg" alt="" className="w-6 h-6 bg-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black pt-3"></div>
    </>
	)
}

export default HomePage;

export const query = graphql`
query {
  prismicHomePage {
    uid
    data {
      meta_description
      page_title
      banner_image {
        gatsbyImageData
        alt
      }
      banner_text {
        richText
      }
      email
      phone
      address
      jobs
      services_text {
        richText
      }
      services {
        text {
          richText
        }
        image {
          gatsbyImageData
          alt
        }
      }
      about_text {
        richText
      }
      about_image {
        gatsbyImageData
        alt
      }
      why_text {
        richText
      }
      why {
        text {
          richText
        }
        image {
          gatsbyImageData
          alt
        }
      }
      review_text {
        richText
      }
      review_quote {
        richText
      }
      review_author
      review_company
      projects_text {
        richText
      }
      projects_text_2 {
        richText
      }
      projects {
        image {
          gatsbyImageData
          alt
        }
        text {
          richText
        }
      }
      action_image {
        gatsbyImageData
        alt
      }
      action_text {
        richText
      }
      logos {
        image {
          gatsbyImageData
          alt
        }
      }
    }
  }
}
`