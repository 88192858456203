import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { linkResolver } from '../utils/link_resolver'

const richText = ({props}) => {
  return (
    <div className="
      max-w-none 
      prose 
      prose-h1:font-verdana 
      prose-h1:font-bold 
      prose-h1:leading-none 
      prose-h1:text-4xl 
      lg:prose-h1:text-7xl 
      prose-h1:mb-0 
      prose-h2:font-verdana 
      prose-h2:font-bold 
      prose-h2:leading-none 
      prose-h2:text-2xl 
      lg:prose-h2:text-5xl 
      prose-h2:mb-6 
      prose-h3:font-verdana 
      prose-h3:font-bold 
      prose-h3:leading-none 
      prose-h3:text-lg 
      prose-h3:mb-3 
      leading-normal
      ">
      <PrismicRichText linkResolver={linkResolver} field={props} />
    </div>
  )
}

export default richText